const baseApiUrl = 'https://dev.sintra-soh.com';
const apiUrl = `${baseApiUrl}/api/`;
const graphQlUrl = `${baseApiUrl}/pimcore-graphql-webservices/webapp`;

export const environment = {
    production: false,
    name: 'remote-development',
    appName: 'SOH - Dev App Retail',
    storageVersion: '1.0.0',
    baseApiUrl,
    apiUrl,
    graphQlUrl,
    authInterceptorUrls: [apiUrl, graphQlUrl],
    mockApiUrl: 'https://localhost:3002/',
    logUrl: '',
    sockets: {url: ''},
    tokenExpirationTimeLimit: 600000, // 10 minutes
    httpRetryConfig: {
        enabled: true,
        verbs: {
            get: {enabled: true, timeout: 30000, maxRetries: 2, retryForStatusesCodes: [500]},
            post: {enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500]},
            patch: {enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500]},
            put: {enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500]},
            delete: {enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500]},
        },
    },
    graphQlConfig: {
        enabled: true,
        requestTimeout: 10000,
    },
    loggerConfig: {
        enabled: true,
        maxLogRentention: 20,
        logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
        logDecoratedExceptions: true,
        logExceptions: true,
        saveLogMessagesInterval: 30000,
        logUrl: '',
        sendLogs: true,
        sendRequestBody: true,
        sendQueryParams: true,
    },
    tagManager: {
        enabled: false,
        apiKey: '',
    },
    registerServiceWorker: true,
    httpPassword: 'SOHub4Demo',
    mainLogoUrl: '',
    customerLogoUrl: '/assets/logo/dev.png',
    apiProperties: {
        size: 'size',
        sizeQuery: 'size {... on object_ProductSize {code}}',
        color: 'color',
        colorQuery: 'color {... on object_Color {name}}',
    },
    returns: {
        isModuleEnabled: true,
        enableReturnedStatusButton: false,
    },
    clickCollect: {
        isModuleEnabled: true,
        showClickCollectCode: false,
    },
    shipFromStore: {
        isModuleEnabled: true
    }
};
